.Reasons {
  padding: 0 4rem;
  display: flex;
  gap: 2rem;
  align-self: center;
}
.left-r {
  flex: 1 1;
  display: grid;

  gap: 1rem;
}
.left-r > img {
  object-fit: cover;
  width: 38rem;
  height: auto;
}

.right-r {
  flex: 1;
  text-transform: uppercase;
  gap: 1rem;
  display: flex;
  flex-direction: column;
}
.right-r > span {
  font-weight: bold;
  color: var(--red);
}
.right-r > div {
  color: black;
  font-size: 3rem;
  font-weight: bold;
}
.details-r {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
.details-r > div {
  display: flex;
  font-size: 1rem;
  gap: 1rem;
}
.details-r > div > img {
  width: 2rem;
  height: 2rem;
}

@media screen and (max-width: 900px) {
  .Reasons {
    flex-direction: column;
    align-items: center;
  }
  .left-r {
    overflow: hidden;
  }

  .left-r > img {
    object-fit: cover;
    width: 30rem;
    height: auto;
    max-height: 110%;
    max-width: 110%;
  }

  .right-r {
    text-overflow: ellipsis;
  }
}
