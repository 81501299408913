.header {
  display: flex;
  justify-content: space-between;
}
.logo {
  width: 15rem;
  height: 3rem;
}
.header-menu {
  list-style: none;
  display: flex;
  gap: 2rem;
  color: rgb(0, 0, 0);
  margin-right: 18%;
  position: relative;
}
.header-menu > li:hover {
  color: var(--red);
  cursor: pointer;
}

.header-menu > :nth-child(5) {
  display: none;
}

.header-menu > :nth-child(5):hover {
  color: white;
}

.close-button {
  position: absolute;
  top: 1rem;
  right: 1rem;
  font-size: 1.2rem;
  font-weight: bold;
  cursor: pointer;
  color: #fff;
}

@media only screen and (max-width: 575px) {
  .header > :nth-child(2) {
    position: fixed;
    right: 2rem;
    z-index: 99;
  }
  .header-menu {
    flex-direction: column;
    background-color: var(--red);
    opacity: 0.9;
    color: rgb(255, 255, 255);
    padding: 2rem;
  }
  .header-menu > :nth-child(5) {
    color: white;
    cursor: none;
    display: block;
  }
}

@media screen and (min-width: 576px) and (max-width: 767px) {
  .header > :nth-child(2) {
    position: fixed;
    right: 2rem;
    z-index: 99;
  }
  .header-menu {
    flex-direction: column;
    background-color: var(--red);
    opacity: 0.9;
    color: rgb(255, 255, 255);
    padding: 2rem;
  }
  .header-menu > :nth-child(5) {
    color: white;
    cursor: none;
    display: block;
  }
}

@media screen and (min-width: 768px) and (max-width: 991px) {
  .header > :nth-child(2) {
    position: fixed;
    right: 2rem;
    z-index: 99;
  }
  .header-menu {
    flex-direction: column;
    background-color: var(--red);
    opacity: 0.9;
    color: rgb(255, 255, 255);
    padding: 2rem;
  }
  .header-menu > :nth-child(5) {
    color: white;
    cursor: none;
    display: block;
  }
}

@media screen and (min-width: 992px) and (max-width: 990px) {
  .header > :nth-child(2) {
    position: fixed;
    right: 2rem;
    z-index: 99;
  }
  .header-menu {
    flex-direction: column;
    background-color: var(--red);
    opacity: 0.9;
    color: rgb(255, 255, 255);
    padding: 2rem;
  }
}
