.Contact {
  padding: 0 2rem;
  display: flex;
  gap: 1rem;
}
.left-c {
  display: flex;
  flex: 10.1;
  gap: 2rem;
  flex-direction: column;
  color: black;
  float: right;
  margin-left: 18%;
  padding-top: 1.9rem;
}
.left-c > :nth-child(1) {
  font-weight: bold;
  font-size: 2rem;
  text-transform: uppercase;
}
.left-c > :nth-child(2) {
  font-size: 1rem;
  text-transform: none;
}
.left-c > :nth-child(3) {
  font-size: 1rem;
  text-transform: none;
  line-height: 0rem;
}
.left-c > :nth-child(4) {
  font-size: 1rem;
  text-transform: none;
  line-height: 0rem;
}

.left-c > div {
  display: flex;
  gap: 1rem;
}
.right-c {
  display: flex;
  flex: 16.1;
  gap: 2rem;
  flex-direction: column;
  color: black;
  float: right;
  padding-bottom: 2%;
  margin-right: 18%;
}
.right-c > :nth-child(1) {
  font-weight: bold;
  font-size: 2rem;
  text-transform: uppercase;
}
.right-c > :nth-child(2),
.right-c > :nth-child(3) .right-c > :nth-child(4) .right-c > :nth-child(5) {
  font-size: 1rem;
  text-transform: none;
}

a:link {
  color: black;
  text-decoration: none;
}

input[type="text"],
input[type="email"] {
  width: 100%;
  border: 1;
  border-color: black;
  background: white;
  height: 50px;
  font-size: 16px;
  color: black;
  padding: 20px;
  box-sizing: border-box;
  border-radius: 10px;
  margin-top: 1%;
  margin-bottom: 1%;
}

textarea[name="message"] {
  width: 100%;
  border: 1;
  border-color: black;
  background: white;
  height: 50px;
  font-size: 16px;
  color: black;
  padding: 20px;
  box-sizing: border-box;
  min-height: 150px;
  border-radius: 10px;
  margin-top: 1%;
  margin-bottom: 1%;
}

.email-container {
  display: flex;
  gap: 3rem;
  background-color: gray;
  padding: 1rem 2rem;
}
.email-container > input {
  background-color: transparent;
  border: none;
  outline: none;
  color: var(--lightgray);
}
::placeholder {
  color: var(--lightgray);
}
.btn-c {
  background-color: var(--red);
  color: white;
  cursor: pointer;
  font-size: 11px;
  font-weight: bold;
  letter-spacing: 1px;
  text-decoration: none;
  padding: 8px 10px;
  border: 1px solid transparent;
  border-radius: 5px;
  text-align: center;
  width: 6rem;
}

@media screen and (max-width: 968px) {
  .left-c {
    font-size: x-large;
    flex-direction: column;
    position: absolute;
    display: flex;
    margin-bottom: 0rem;
    margin-right: 0rem;
    margin-left: 2rem;
  }
  .right-c {
    padding: 2rem;
    margin-right: 0rem;
    display: flex;
    margin-top: 16rem;
    margin-bottom: -2rem;
  }
}

@media screen and (min-width: 876px) {
  .left-c {
    font-size: x-large;
    flex-direction: column;
  }
  .right-c {
    padding: 2rem;
  }
}
