/* make variables */
:root {
  --lightgray: #d9d9d9;
  --gray: #9c9c9c;
  --red: #eb5757;
  --darkGrey: #464d53;
  --caloryCard: #656565;
  --planCard: linear-gradient(210.41deg, #fa5042 1.14%, #ff8080 100.75%);
  --appColor: #ffffff;
}

.App {
  background-color: var(--appColor);
  display: flex;
  flex-direction: column;
  gap: 6rem;
  overflow: hidden;
}

.btn {
  background-color: white;
  padding: 0.5rem;
  font-weight: bold;
  border: 4px solid transparent;
  transition: 300ms all;
  border-radius: 15px;
}
.btn:hover {
  cursor: pointer;
}

.stroke-text {
  color: transparent;
  font-family: Arial, Helvetica, sans-serif;
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: rgb(0, 0, 0);
}
