.bordar {
  display: flex;
  gap: 1rem;
  padding-top: 0rem;
  padding-bottom: 1rem;
  border-radius: 10%;
  position: center;
  align-items: center;
}

.testimonials {
  display: flex;
  gap: 1rem;
  padding-top: 1rem;
  padding-left: 20%;
  padding-right: 20%;
  border-radius: 10%;
}

.left-t {
  display: flex;
  flex: 1.1;
  gap: 2rem;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  text-transform: uppercase;
  color: black;
}
.left-t > :nth-child(1) {
  color: var(--red);
  font-weight: bold;
}
.left-t > :nth-child(2) {
  font-weight: bold;
  font-size: 2rem;
}
.left-t > :nth-child(3) {
  text-transform: none;
  line-height: 40px;
  font-size: 1.5rem;
}
.right-t {
  flex: 1;
  position: relative;
}

.arrows {
  display: flex;
  gap: 1rem;
  justify-content: center;
  bottom: 1rem;
  left: 3rem;
  padding-bottom: 1rem;
}
.arrows > img {
  width: 1.5rem;
  cursor: pointer;
}

.stars > img {
  width: 15rem;
  padding: 1rem;
}

.facebook > img {
  width: 15rem;
  cursor: pointer;
}

@media screen and (max-width: 767px) {
  .testimonials {
    flex-direction: column;
    align-items: center;
  }
  .left-t > :nth-child(2),
  .left-t > :nth-child(3) {
    font-size: large;
  }
  .right-t {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 2rem;
  }
  .right-t > div {
    position: relative;
    display: none;
  }
  .right-t > img {
    top: 0;
    right: 0;
    position: relative;
    align-self: center;
  }
  .right-t > :last-child {
    display: block;
    bottom: 0;
    left: 0;
  }
}
