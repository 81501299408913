.hero {
  display: flex;
  justify-content: space-between;
}
.left-h {
  padding: 3rem;
  padding-top: 1.5rem;
  flex: 3;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  margin-left: 3%;
}
.right-h {
  background-color: var(--red);
  flex: 1;
  position: relative;
}

.the-best-ad {
  margin-top: 5rem;
}

.the-best-ad {
  margin-top: 4rem;
}

.hero-text {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  text-transform: uppercase;
  font-size: 2.5rem;
  font-weight: bold;
  color: black;
  text-overflow: inherit;
}

.hero-text > div:nth-of-type(3) {
  font-size: 1.25rem;
  font-weight: 200;
  text-transform: none;
  letter-spacing: 1px;
  width: 80%;
}

.hero-text-red {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  text-transform: uppercase;
  font-size: 2.5rem;
  font-weight: bold;
  color: var(--red);
  text-overflow: inherit;
}

.figures {
  display: flex;
  gap: 2rem;
}
.figures > div {
  display: flex;
  flex-direction: column;
}
.figures > div > span:nth-of-type(1) {
  color: black;
  font-size: 2.2rem;
}
.figures > div > span:nth-of-type(2) {
  color: rgb(48, 48, 48);
  text-transform: uppercase;
}

.hero-buttons {
  display: flex;
  gap: 1rem;
  font-weight: normal !important;
}
.hero-buttons > :nth-child(1) {
  color: white;
  background-color: var(--red);
  width: 9rem;
  border-radius: 15px;
}

.right-h > .btn {
  position: absolute;
  right: 3rem;
  top: 2rem;
  color: black;
  width: 8rem;
}

.hero-image {
  position: absolute;
  top: 9rem;
  right: 6rem;
  width: 45rem;
}

.hero-image-back {
  position: absolute;
  top: 6rem;
  right: 10rem;
  margin-right: 8rem;
  z-index: -1;
  width: 10rem;
}

@media only screen and (max-width: 575px) {
  .right-h > .btn {
    display: none;
  }

  .right-h {
    display: none;
  }

  .left-h {
    padding: 2rem;
  }

  .figures {
    gap: 1.5rem;
  }

  .figures > div > span:nth-of-type(1) {
    font-size: large;
    font-weight: bold;
  }

  .figures > div > span:nth-of-type(2) {
    font-size: small;
  }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
  .right-h > .btn {
    display: none;
  }

  .right-h {
    display: none;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .hero {
    flex-direction: column;
  }
  .the-best-ad {
    margin-top: 0;
    font-size: small;
    align-self: center;
    transform: scale(0.8);
  }
  .hero-text {
    font-size: xx-large;
    align-items: center;
    justify-content: center;
  }
  .hero-text > div:nth-of-type(3) {
    font-size: small;
    font-weight: 200;
    letter-spacing: 1px;
    text-align: center;
  }
  .hero-buttons {
    justify-content: center;
  }
  .figures {
    text-align: center;
    justify-content: center;
  }

  .figures > div {
    justify-content: center;
  }

  .figures > div > span:nth-of-type(1) {
    font-size: large;
  }
  .figures > div > span:nth-of-type(2) {
    font-size: small;
  }
  .right-h {
    position: relative;
    background: none;
  }

  .right-h > .btn {
    display: none;
  }

  .hero-image {
    display: none;
  }
  .hero-image-back {
    display: none;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .hero-image {
    display: none;
  }
  .hero-image-back {
    display: none;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1729px) {
  .the-best-ad {
    margin-top: 0rem;
  }

  .hero-text {
    font-size: 2rem;
  }

  .hero-text-red {
    font-size: 2rem;
  }

  .hero-text > div:nth-of-type(3) {
    font-size: 0.9rem;
  }

  .figures > div > span:nth-of-type(1) {
    font-size: 1.5rem;
  }
  .figures > div > span:nth-of-type(2) {
    font-size: 0.8rem;
  }

  .hero-image {
    width: 170%;
    top: 35%;
  }

  .hero-image-back {
    width: 35%;
    top: 35%;
    padding-left: 10%;
  }
}
