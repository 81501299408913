.about {
  display: flex;
  gap: 1rem;
  padding: 0 2rem;
  margin-top: 1%;
}
.right-a {
  display: flex;
  flex: 1.5;
  gap: 2rem;
  flex-direction: column;
  color: white;
  float: right;
  background-color: rgb(58, 58, 58);
  padding-left: 15%;
  padding-right: 2%;
  padding-top: 2%;
  padding-bottom: 4%;
  margin-right: 20%;
}
.right-a > :nth-child(1) {
  font-weight: bold;
  font-size: 3rem;
  text-transform: uppercase;
}
.right-a > :nth-child(2),
.right-a > :nth-child(3),
.right-a > :nth-child(4),
.right-a > :nth-child(5),
.right-a > :nth-child(6) {
  font-size: 1rem;
  text-transform: none;
}
.right-a > :nth-child(7) {
  font-weight: bold;
  font-size: 1rem;
  text-transform: none;
  letter-spacing: 2px;
  line-height: 0rem;
}
.left-a {
  flex: 1;
  position: relative;
}
.left-a > img {
  position: absolute;
  width: 19rem;
  height: 24rem;
  object-fit: cover;
  left: 8rem;
  top: 2.5rem;
  margin-left: 30%;
  object-fit: cover;
}
.left-a > :nth-child(1) {
  position: absolute;
  width: 19rem;
  height: 24rem;
  border: 2px solid var(--red);
  background-color: transparent;
  left: 9rem;
  top: 1.4rem;
  margin-left: 30%;
}
.left-a > :nth-child(2) {
  position: absolute;
  width: 19rem;
  height: 24rem;
  left: 7rem;
  top: 4.5rem;
  background: var(--planCard);
  margin-left: 30%;
}

@media screen and (max-width: 767px) {
  .right-a {
    padding-left: 10%;
    padding-right: 8%;
    padding-top: 4%;
    padding-bottom: 6%;
    text-overflow: ellipsis;
    margin-left: 5%;
    margin-right: 5%;
  }

  .right-a > :nth-child(1) {
    font-weight: bold;
    font-size: 3rem;
    text-transform: uppercase;
  }
  .right-a > :nth-child(2),
  .right-a > :nth-child(3),
  .right-a > :nth-child(4),
  .right-a > :nth-child(5),
  .right-a > :nth-child(6) {
    font-size: 1rem;
    text-transform: none;
  }
  .right-a > :nth-child(7) {
    font-weight: bold;
    font-size: auto;
    text-transform: none;
    letter-spacing: 2px;
    line-height: 1.5rem;
  }

  .left-a {
    display: none;
  }
}

@media screen and (max-width: 970px) and (min-width: 768px) {
  .right-a {
    padding-left: 10%;
    padding-right: 8%;
    padding-top: 4%;
    padding-bottom: 6%;
    margin-left: 5%;
    margin-right: 5%;
  }
  .left-a {
    display: none;
  }
}

@media screen and (min-width: 971px) and (max-width: 1382px) {
  .about {
    flex-direction: column;
  }

  .right-a > :nth-child(1) {
    font-size: 220%;
  }
  .right-a > :nth-child(2),
  .right-a > :nth-child(3),
  .right-a > :nth-child(4),
  .right-a > :nth-child(5),
  .right-a > :nth-child(6),
  .right-a > :nth-child(7) {
    font-size: 105%;
  }

  .right-a {
    padding-left: 25%;
    padding-right: 8%;
    padding-top: 3%;
    padding-bottom: 4%;
    margin-left: 20%;
    margin-right: 5%;
  }

  .left-a > img {
    top: 3.5rem;
    margin-left: -5%;
  }

  .left-a > :nth-child(1) {
    top: 2.4rem;
    margin-left: -5%;
  }
  .left-a > :nth-child(2) {
    top: 5.5rem;
    margin-left: -5%;
  }
}
